<template>
  <footer>
    <div class="left-col">
      <transition v-if="currentSubtitleContent" name="fade" mode="out-in">
        <div class="slide" :key="currentSubtitleContent">
          <img v-if="logo" :src="imagePath" />
          <div class="title">{{ currentSubtitleContent }}</div>
        </div>
      </transition>
    </div>
    <div class="right-col">
      <transition name="slide-down" mode="out-in">
        <component
          :is="miniFooterCurrentContent.label"
          :key="miniFooterCurrentIndex"
          :data="miniFooterCurrentContent"
        />
      </transition>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import MiniClock from "../templates/FooterSubTemplates/MiniClock.vue";
import MiniWeather from "../templates/FooterSubTemplates/MiniWeather.vue";
import MiniCurrency from "../templates/FooterSubTemplates/MiniCurrency.vue";
import weatherManager from "../manager/modules/weather-manager";
import currencyManager from "../manager/modules/currency-manager";
import jwtHelper from "../shared/jwt-helper";
import accountStorage from "../storage/modules/account";
export default {
  name: "footer-bar",
  props: ["data"],
  components: { MiniClock, MiniWeather, MiniCurrency },
  data() {
    return {
      i: 0,
      text: "",
      destroyed: false,
      quickInfoInterval: null,
      miniFooterContents: [
        { label: "MiniClock", text: null, image: "images/icon/clock.svg" },
      ],
      subtitleIndex: 0,
      miniFooterCurrentIndex: 0,
    };
  },
  computed: {
    ...mapGetters("footerBar", ["footerTexts", "time"]),
    ...mapGetters("currency", ["allCurrencies"]),
    ...mapGetters(["logo"]),
    model() {
      return this.$store.state.currency;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.logo}`;
    },
    miniFooterCurrentContent() {
      return this.miniFooterContents[this.miniFooterCurrentIndex];
    },
    currentSubtitleContent() {
      return this.footerTexts[this.subtitleIndex];
    },
  },
  methods: {
    async initialMiniFooterContents() {
      this.miniFooterContents[0].text = this.time;
      await this.fetchWeathers();
      this.miniFooterContents.push({
        label: "MiniWeather",
        text: "",
        image: "",
      });
      await this.fetchCurrencies();
      this.miniFooterContents.push(
        ...this.allCurrencies.map((currency) => ({
          ...currency,
          label: "MiniCurrency",
        }))
      );
    },
    async fetchWeathers() {
      var accountId = jwtHelper.getAccountId();
      accountStorage.getAccountById(accountId).then(async (account) => {
        if (account && account.cityId) {
          await weatherManager.downloadWeatherForecasts(account.cityId);
        }
      });
    },
    async fetchCurrencies() {
      await currencyManager.downloadCurrencyValues();
    },
  },
  created() {
    this.$store.dispatch("footerBar/startUpdateTimeInterval");
  },
  mounted() {
    this.initialMiniFooterContents();
    this.quickInfoInterval = setInterval(() => {
      this.miniFooterContents[0].text = this.time;
      this.subtitleIndex = (this.subtitleIndex + 1) % this.footerTexts.length;
      this.miniFooterCurrentIndex =
        (this.miniFooterCurrentIndex + 1) % this.miniFooterContents.length;
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.quickInfoInterval);
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 6.5vh;
  max-height: 120px;
  background: rgb(58, 90, 143);
  background: radial-gradient(
    circle,
    rgba(58, 90, 143, 1) 0%,
    rgba(27, 40, 74, 1) 100%
  );
  color: white;
  text-align: center;
  z-index: 999;
  display: flex;
  .left-col {
    width: 88%;
    .slide {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: 50px;
        margin-right: 10px;
        margin-left: 10px;
      }

      .title {
        max-width: 90%;
        font-size: 31px;
        font-family: Montserrat-Regular;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .right-col {
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(27, 167, 226);
    background: linear-gradient(
      90deg,
      rgba(27, 167, 226, 1) 0%,
      rgba(23, 153, 158, 1) 100%
    );
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-down-enter {
  transform: translateY(-30%);
  opacity: 0;
}
.slide-down-leave-to {
  transform: translateY(30%);
  opacity: 0;
}
.slide-down-enter-to {
  transform: translateY(0);
  opacity: 1;
}
</style>

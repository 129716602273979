<template>
  <div class="mini-currency-container">
    <b-img
      v-if="imagePath"
      class="currency-img"
      :src="imagePath"
      alt="Footer Image"
    ></b-img>

    <div class="currency-info">
      <div class="currency-name">
        {{
          data.shortKey === "USD"
            ? "DOLAR"
            : data.shortKey === "GA"
            ? "ALTIN"
            : data.name
        }}
      </div>
      <div class="currency-value">{{ data.currencyValue.toFixed(2) }} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    imagePath() {
      return `/images/currency/${
        this.data.shortKey === "USD"
          ? "dollar"
          : this.data.shortKey === "GA"
          ? "gold"
          : "euro"
      }.png`;
    },
  },
};
</script>
<style scoped lang="scss">
.mini-currency-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat-Regular;
  .currency-img {
    width: 2.3rem;
    max-width: 60px;
    filter: brightness(0) saturate(10%) invert(26%) sepia(10%) saturate(7900%)
      hue-rotate(190deg) brightness(80%);
  }
  .currency-info {
    text-align: start;
    margin-inline-start: 8px;
    .currency-name {
      font-size: 1.8rem;
      line-height: 0;
      margin-bottom: 1.8rem;
    }
    .currency-value {
      font-size: 1.8rem;
      line-height: 0;
    }
  }
}
</style>
